<template name="ForDownload">
  <div class="py20 px10">
    <h3 class="h4 weight-500 m0 pb10 uppercase">
      {{ $t('Dokumentacja i pliki do pobrania') }}
    </h3>
    <p>
      {{ $t('Potrzebujesz zapisać pliki na dysku do wykorzystania w swoim projekcie? Poniżej znajdziesz odnośniki do pobrania plików.') }}
    </p>
    <h3 class="h4 weight-500 m0 py10 uppercase" v-if="prod.catalog1 || prod.catalog2">
      {{ $t('Karta katalogowa') }}
    </h3>
    <div class="flex middle-xs" v-if="prod.catalog1">
      <img :src="'/assets/svg/pdf_icon.svg'" alt="pdf icon" width="auto" height="40px">
      <span class="h4 ml10">{{ $t('Karta katalogowa') }}</span>
      <a class="ml10 cl-orange pointer" :href="downloadLink + prod.catalog1" target="_blank">pobierz</a>
    </div>
    <div class="flex middle-xs mt10" v-if="prod.catalog2">
      <img :src="'/assets/svg/pdf_icon.svg'" alt="pdf icon" width="auto" height="40px">
      <span class="h4 ml10">{{ $t('Karta katalogowa 2') }}</span>
      <a class="ml10 cl-orange pointer" :href="downloadLink + prod.catalog2" target="_blank">pobierz</a>
    </div>
    <h3 class="h4 weight-500 m0 py10 uppercase" v-if="getLabelforValue('manufacturer', prod.manufacturer)">
      {{ $t('Paleta kolorów') }}
    </h3>
    <div class="flex middle-xs" v-if="getLabelforValue('manufacturer', prod.manufacturer)">
      <img :src="'/assets/svg/pdf_icon.svg'" alt="pdf icon" width="auto" height="40px">
      <span class="h4 ml10">{{ $t('Wzornik kolorów') }}</span>
      <a class="ml10 cl-orange pointer" :href="'/assets/paleta-kolorow/' + paletaLink + '.pdf'" target="_blank">pobierz</a>
    </div>
    <h3 class="h4 weight-500 m0 py10 uppercase" v-if="prod.model3d">
      {{ $t('Model 3D') }}
    </h3>
    <div class="flex middle-xs" v-if="prod.model3d">
      <img :src="'/assets/svg/zip_icon.svg'" alt="pdf icon" width="auto" height="40px">
      <span class="h4 ml10">{{ $t('Modele 3D produktu') }}</span>
      <a class="ml10 cl-orange pointer" :href="downloadLink + prod.model3d" target="_blank">pobierz</a>
    </div>
    <h3 class="h4 weight-500 m0 py10 uppercase" v-if="prod.drawing1 || prod.drawing2">
      {{ $t('Rysunki techniczne') }}
    </h3>
    <div class="flex middle-xs" v-if="prod.drawing1">
      <img :src="'/assets/svg/pdf_icon.svg'" alt="pdf icon" width="auto" height="40px">
      <span class="h4 ml10">{{ $t('Rysunek techniczny') }}</span>
      <a class="ml10 cl-orange pointer" :href="downloadLink + prod.drawing1" target="_blank">pobierz</a>
    </div>
    <div class="flex middle-xs" v-if="prod.drawing2">
      <img :src="'/assets/svg/pdf_icon.svg'" alt="pdf icon" width="auto" height="40px">
      <span class="h4 ml10">{{ $t('Rysunek techniczny 2') }}</span>
      <a class="ml10 cl-orange pointer" :href="downloadLink + prod.drawing2" target="_blank">pobierz</a>
    </div>
    <h3 class="h4 weight-500 m0 py10 uppercase" v-if="Object.keys(catalogs).length !== 0">
      {{ $t('Katalogi do pobrania') }}
    </h3>
    <div v-if="Object.keys(catalogs).length !== 0">
      <div class="flex middle-xs" v-for="catalog in catalogs" :key="catalog.id">
        <img :src="'/assets/svg/pdf_icon.svg'" alt="pdf icon" width="auto" height="40px">
        <span class="h4 ml10">{{ $t(catalog.title) }}</span>
        <a class="ml10 cl-orange pointer" :href="`/assets/${catalog.link}`" target="_blank">pobierz</a>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import get from 'lodash-es/get'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'

export default {
  name: 'ForDownload',
  props: {
    prod: {
      type: Object,
      required: true
    }
  },
  mixins: [getLabelMixin],
  data () {
    return {
      distributers_catalogs: {
        'Brem': {
          1: {
            id: 'BREM-1',
            title: 'Brem katalog pelny 2018',
            link: 'BREM-katalog-pelny-2018.pdf'
          },
          2: {
            id: 'BREM-2',
            title: 'Brem lookbook',
            link: 'BREM-lookbook.pdf'
          },
          3: {
            id: 'BREM-3',
            title: 'Brem News 2018-2019',
            link: 'BREM-NEWS-2018-2019.pdf'
          },
          4: {
            id: 'BREM-4',
            title: 'Brem nowosci skrot 2018',
            link: 'BREM-nowosci-skrot-2018.pdf'
          }
        },
        'K8': {
          1: {
            id: 'K8-1',
            title: 'K8 katalog 2019 pelny artykulygrzewcze',
            link: 'K8-katalog-2019-pelny.pdf'
          },
          2: {
            id: 'K8-2',
            title: 'K8 katalog 2019 podstawowy artykulygrzewcze',
            link: 'K8-katalog-2019-podstawowy.pdf'
          }
        },
        'Radox': {
          1: {
            id: 'RADOX-1',
            title: 'Poznaj grzejniki RADOX',
            link: 'Radox_Radiators_preznetacja.pdf'
          },
          2: {
            id: 'RADOX-2',
            title: 'Radox Katalog Premium',
            link: 'RADOX-prezentacja-produktow-wyzsza-jakosc(1).pdf'
          }
        },
        'Irsap': {
          1: {
            id: 'IRSAP-1',
            title: 'Katalog produktow IRSAP 2019',
            link: 'Katalog_produktow_IRSAP_ 2019_artykulygrzewcze.pdf'
          }
        }
      }
    }
  },
  computed: {
    paletaLink () {
      return this.getLabelforValue('palette_id', this.prod.palette_id) === 'Spa_collection'
        ? 'AG-SPA'
        : this.getLabelforValue('manufacturer', this.prod.manufacturer)
    },
    catalogs () {
      let manufacturer = this.getLabelforValue('manufacturer', this.prod.manufacturer)
      let items = get(this.distributers_catalogs, manufacturer, {})
      return items
    },
    downloadLink () {
      return config.server.downloadLink
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
